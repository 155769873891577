import React from 'react';
import { IListing } from './types';

const Listing = ({ list }: IListing): React.ReactElement => (
    <>
        <h3 className="text-l lg:text-xl font-semibold text-slate-900">{list.headline}</h3>
        <ul className="flex flex-wrap my-4">
        {list.text.map(listItem => (
            <li key={listItem} className="mr-3 mb-2 lg:mb-3 px-5 lg:px-6 py-1 lg:py-2 inline-block rounded-full bg-slate-700 text-white">{listItem}</li>
        ))}
        </ul>
    </>
);

export default Listing;
