import { Status } from '../types';
import { TBlocks } from '../types/blocks';
import { HomepageAction, IRequestHomepageAction, IRequestHomepagePendingAction, IRequestHomepageRejectedAction, IRequestHomepageSuccessAction } from '../types/homepage';

export const requestHomepage = (): IRequestHomepageAction => ({
    type: HomepageAction.REQUEST_HOMEPAGE,
});

export const requestHomepagePending = (): IRequestHomepagePendingAction => ({
    type: HomepageAction.REQUEST_HOMEPAGE_PENDING,
});

export const requestHomepageRejected = (): IRequestHomepageRejectedAction => ({
    type: HomepageAction.REQUEST_HOMEPAGE_REJECTED,
});

export const requestHomepageSuccess = (blocks: TBlocks): IRequestHomepageSuccessAction => ({
    type: HomepageAction.REQUEST_HOMEPAGE_SUCCESS,
    payload: {
        bySlug: blocks,
        status: Status.LOADED,
    },
});
