import React from 'react';
import { useLocation } from 'react-router-dom';
import Link from '../Link';
import { ReactComponent as GithubIcon } from '../../assets/Github.svg';
import { ReactComponent as XingIcon } from '../../assets/Xing.svg';

const Footer = (): React.ReactElement => {
    const year = new Date().getFullYear();
    const location = useLocation();
    const [isHome, setIsHome] = React.useState<boolean>(false);
    React.useEffect(() => setIsHome(location.pathname === '/'), [location]);

    return (
        <footer className="px-4 pb-4 pt-12 bg-slate-50">
            <section>
                <ul className="flex flex-row flex-nowrap justify-center items-center mb-10">
                    <li className="mx-4">
                        <Link
                            to="https://github.com/frehder"
                            title="github.com/frehder"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <GithubIcon className="h-12 lg:h-16" />
                        </Link>
                    </li>
                    <li className="mx-4">
                        <Link
                            to="https://www.xing.com/profile/Florian_Rehder2"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <XingIcon className="h-12 lg:h-16" />
                        </Link>
                    </li>
                </ul>
            </section>
            <section>
                <p className="text-base text-center mb-2">&copy; {year} Florian Rehder</p>
                <nav>
                    <ul className="flex flex-row flex-nowrap justify-center items-center">
                        {!isHome && (
                            <li className="mx-2"><Link to="/" className="text-base text-slate-900 underline decoration-slate-200 decoration-2 hover:text-blue-600 hover:decoration-blue-500 transition-colors ease-in-out delay-150">Home</Link></li>
                        )}
                        <li className="mx-2"><Link to="/impressum" className="text-base text-slate-900 underline decoration-slate-200 decoration-2 hover:text-blue-600 hover:decoration-blue-500 transition-colors ease-in-out delay-150">Impressum</Link></li>
                        <li className="mx-2"><Link to="/datenschutz" className="text-base text-slate-900 underline decoration-slate-200 decoration-2 hover:text-blue-600 hover:decoration-blue-500 transition-colors ease-in-out delay-150">Datenschutz</Link></li>
                    </ul>
                </nav>
            </section>
        </footer>
    );
}

export default Footer;
