import { combineReducers } from 'redux';
import homepageReducer from './homepage';
import legalReducer from './legal';

const rootReducer = combineReducers({
    homepage: homepageReducer,
    legal: legalReducer,
});

export default rootReducer;
