import { IBlocksState } from './blocks';

export enum Status {
    IDLE = 'IDLE',
    PENDING = 'PENDING',
    LOADED = 'LOADED',
    REJECTED = 'REJECTED',
}

export interface IRootState {
    homepage: IBlocksState,
    legal: IBlocksState,
}
