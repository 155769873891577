import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';
import * as homepageSagas from './homepage';
import * as legalSagas from './legal';

export default function* root(): Generator<AllEffect<ForkEffect<unknown>>, void, unknown> {
    yield all([
        ...Object.values(homepageSagas),
        ...Object.values(legalSagas),
    ].map(fork));
}
