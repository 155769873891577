import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import middleware, { sagaMiddleware } from './middleware';
import rootSaga from './sagas';
import rootReducer from './reducers';

const preloadedState = undefined;
const composeEnhancer = composeWithDevTools({});
const store = createStore(rootReducer, preloadedState, composeEnhancer(applyMiddleware(...middleware)));

sagaMiddleware.run(rootSaga)

export default store;
