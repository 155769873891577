import { IBlocksState } from "./blocks";

export enum LegalAction {
    REQUEST_LEGAL = 'REQUEST_LEGAL',
    REQUEST_LEGAL_PENDING = 'REQUEST_LEGAL_PENDING',
    REQUEST_LEGAL_REJECTED = 'REQUEST_LEGAL_REJECTED',
    REQUEST_LEGAL_SUCCESS = 'REQUEST_LEGAL_SUCCESS',
}

export interface IRequestLegalAction {
    type: LegalAction.REQUEST_LEGAL;
}

export interface IRequestLegalPendingAction {
    type: LegalAction.REQUEST_LEGAL_PENDING;
}

export interface IRequestLegalRejectedAction {
    type: LegalAction.REQUEST_LEGAL_REJECTED;
}

export interface IRequestLegalSuccessAction {
    type: LegalAction.REQUEST_LEGAL_SUCCESS;
    payload: IBlocksState;
}

export type TLegalAction = IRequestLegalAction
    | IRequestLegalPendingAction
    | IRequestLegalRejectedAction
    | IRequestLegalSuccessAction
;
