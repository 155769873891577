import React from 'react';

const Loader = (): React.ReactElement => (
    <div className="w-full md:w-96 max-w-sm">
        <div className="m-4 flex space-x-4 animate-pulse">
            <div className=" h-10 w-10 rounded-full bg-gray-300"></div>
            <div className="flex-1 space-y-4">
                <div className="leading-10">Bitte warten &hellip;</div>
                <div className="space-y-3">
                    <div className="h-2 bg-gray-300 rounded"></div>
                    <div className="grid grid-cols-3 gap-4">
                        <div className="h-2 bg-gray-300 rounded col-span-2"></div>
                        <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                    </div>
                    <div className="h-2 bg-gray-300 rounded"></div>
                </div>
            </div>
        </div>
    </div>
);

export default Loader;
