import React from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../assets/Logo.svg';
import Link from '../Link';

const Header = (): React.ReactElement => {
    const location = useLocation();
    const [isHome, setIsHome] = React.useState<boolean>(false);
    React.useEffect(() => setIsHome(location.pathname === '/'), [location]);

    return (
        <section className="container mx-auto
        flex flex-row flex-nowrap justify-between items-center
        pt-4
         px-3 md:px-6 lg:px-10
        ">
            <div>
                <Link to="/">
                    <LogoIcon
                        className="
                            h-8 lg:h-10
                            fill-slate-800 hover:fill-blue-500 transition-colors ease-in-out delay-150
                        " />
                </Link>
            </div>
            {!isHome && (
            <nav>
                <ul className="flex flex-row flex-nowrap justify-end items-center">
                    <li className="ml-4"><Link isNav to="/" className="text-slate-900 underline decoration-slate-50 decoration-2 hover:text-blue-600 hover:decoration-blue-500 transition-colors ease-in-out delay-150">Home</Link></li>
                </ul>
            </nav>
            )}
        </section>
    );
}

export default Header;
