import React from 'react';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import { ILink } from './types';

const Link = ({ to, isNav = false, target = '_self', title = '', rel = '', children, className, ...rest }: ILink): React.ReactElement => {
    const isExternal = to.includes('http');

    if (isExternal) return (<a href={to} target={target} title={title} rel={rel} className={className} {...rest}>{children}</a>);

    if (isNav) return (<NavLink to={to} title={title} className={className} {...rest}>{children}</NavLink>);

    return (<RouterLink to={to} title={title} className={className} {...rest}>{children}</RouterLink>);
}

export default Link;
