import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestHomepage } from "../../store/actions/homepage";
import { selectStatus } from "../../store/selectors/homepage";
import { Status } from "../../store/types";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import Hero from "../../components/Hero";
import Article from "../../components/Article";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";

const Homepage = (): React.ReactElement => {
  const dispatch = useDispatch();
  const [requestPending, setRequestPending] = React.useState<boolean>(true);
  const pageStatus = useSelector(selectStatus);

  React.useEffect(() => {
    switch (pageStatus) {
      case Status.IDLE:
        dispatch(requestHomepage());
        break;
      case Status.PENDING:
        setRequestPending(true);
        break;
      case Status.LOADED:
        setRequestPending(false);
        break;
    }
  }, [pageStatus, dispatch]);

  if (requestPending) return <Loader />;

  return (
    <>
      <header>
        <Header />
        <Hero />
      </header>
      <main
        className="
                container mx-auto
                mb-8 lg:mb-16
                lg:flex lg:flex-row lg:flex-nowrap lg:justify-start lg:items-top
                lg:space-x-8
            "
      >
        <Article blockId="development" />
        <Article blockId="design" />
      </main>
      <Contact />
      <Footer />
    </>
  );
};

export default Homepage;
