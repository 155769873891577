
export const queryHomepage = {
    query: `
        *[_type == "pageBlock"]{
            "blockId": _id,
            "slug": slug.current,
            headline,
            content,
            "listing": listing[]->{
                "listId": _id,
                headline,
                text,
            },
        }
    `,
    options: {}
};
