import { takeLatest, put, call, ForkEffect } from 'redux-saga/effects';
import { fetch } from "../../sanity";
import { queryLegal } from "../../sanity/query/legal";
import { transformBlocks } from '../../utils/transforms';
import { IBlock, TBlocks } from '../types/blocks';
import { LegalAction } from '../types/legal';
import { requestLegalPending, requestLegalRejected, requestLegalSuccess } from '../actions/legal';

function* requestLegal() {
    yield put(requestLegalPending());

    try {
        const response: Promise<Array<IBlock>> = yield call(fetch, queryLegal);
        const blocks: TBlocks = yield transformBlocks<IBlock, TBlocks>(response);

        yield put(requestLegalSuccess(blocks));
    }
    catch (error) {
        yield put(requestLegalRejected());

        if (error instanceof Error) {
            console.error('saga requestLegal() error:', error);
        }
    }
}

export function* watchRequestLegal(): Generator<ForkEffect<LegalAction>, void, number> {
    yield takeLatest(LegalAction.REQUEST_LEGAL, requestLegal);
}
