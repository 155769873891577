
export const queryLegal = {
    query: `
        *[_type == "legalBlock"]{
            "blockId": _id,
            "slug": slug.current,
            headline,
            content,
        }
    `,
    options: {}
};
