import { IBlocksState } from "./blocks";

export enum HomepageAction {
    REQUEST_HOMEPAGE = 'REQUEST_HOMEPAGE',
    REQUEST_HOMEPAGE_PENDING = 'REQUEST_HOMEPAGE_PENDING',
    REQUEST_HOMEPAGE_REJECTED = 'REQUEST_HOMEPAGE_REJECTED',
    REQUEST_HOMEPAGE_SUCCESS = 'REQUEST_HOMEPAGE_SUCCESS',
}

export interface IRequestHomepageAction {
    type: HomepageAction.REQUEST_HOMEPAGE;
}

export interface IRequestHomepagePendingAction {
    type: HomepageAction.REQUEST_HOMEPAGE_PENDING;
}

export interface IRequestHomepageRejectedAction {
    type: HomepageAction.REQUEST_HOMEPAGE_REJECTED;
}

export interface IRequestHomepageSuccessAction {
    type: HomepageAction.REQUEST_HOMEPAGE_SUCCESS;
    payload: IBlocksState;
}

export type THomepageAction = IRequestHomepageAction
    | IRequestHomepagePendingAction
    | IRequestHomepageRejectedAction
    | IRequestHomepageSuccessAction
;
