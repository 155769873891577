import React from 'react';
import { useSelector } from 'react-redux';
import BlockContent from '@sanity/block-content-to-react';
import { selectBlock } from '../../store/selectors/homepage';

const Hero = (): React.ReactElement | null => {
    const hero = useSelector(selectBlock('hero'));

    if (!hero) return null;

    return (
        <section className="
            hero
            container mx-auto
            flex flex-row flex-nowrap justify-start items-center
        ">
            <div className="
                max-w-screen-lg px-3 md:px-6 lg:px-10 pb-6
                text-lg leading-6 sm:text-xl sm:leading-8 lg:text-3xl lg:leading-10 text-slate-600
            ">
                <BlockContent blocks={hero.headline} serializers={{
                    types: {
                        block: (props: any) => (
                            <p className="mb-1.5 sm:mb-2 text-slate-900 leading-relaxed font-semibold">{props?.children}</p>
                        ),
                    },
                    marks: {
                        strong: (props: any) => (
                            <strong className="text-blue-500 leading-relaxed font-semibold">{props.children}</strong>
                        ),
                    }
                }} />
                <BlockContent blocks={hero.content} serializers={{
                    types: {
                        block: (props: any) => (
                            <p className="mb-1.5 sm:mb-2 leading-relaxed">{props?.children}</p>
                        ),
                    },
                    marks: {
                        strong: (props: any) => (
                            <strong className="text-slate-900 leading-relaxed font-semibold">{props.children}</strong>
                        ),
                        em: (props: any) => (
                            <span className="text-slate-900 leading-relaxed">{props.children}</span>
                        ),
                    }
                }} />
            </div>
        </section>
    );
};

export default Hero;
