import { Status } from '../types';
import { IBlocksState } from '../types/blocks';
import { HomepageAction, THomepageAction } from '../types/homepage';

export const initialHomepageState: IBlocksState = {
    bySlug: {},
    status: Status.IDLE,
}

const homepageReducer = (state = initialHomepageState, action: THomepageAction): IBlocksState => {
    switch (action.type)
    {
        case HomepageAction.REQUEST_HOMEPAGE_SUCCESS:
            return action.payload;

        case HomepageAction.REQUEST_HOMEPAGE_PENDING:
            return {
                bySlug: state.bySlug,
                status: Status.PENDING,
            };

        case HomepageAction.REQUEST_HOMEPAGE_REJECTED:
            return {
                bySlug: state.bySlug,
                status: Status.REJECTED,
            };

        default:
            return state;
    }
};

export default homepageReducer;
