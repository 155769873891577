import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Legal from './views/Legal';
import Homepage from './views/Homepage';
import NotFound from './views/NotFound';

const App = (): React.ReactElement => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/impressum" element={<Legal block="imprint" />} />
                <Route path="/datenschutz" element={<Legal block="privacy_policy" />} />
                <Route path="/" element={<Homepage />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
