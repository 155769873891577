import React from 'react';
import { useSelector } from 'react-redux';
import BlockContent from '@sanity/block-content-to-react';
import { selectBlock } from '../../store/selectors/homepage';
import { IArticle } from './types';
import Listing from '../Listing';

const Article = ({ blockId }: IArticle): React.ReactElement | null => {
    const block = useSelector(selectBlock(blockId));

    if (!block) return null;

    return (
        <article className="
            px-4 pt-6 pb-2 md:px-6 lg:px-10 lg:pt-10 lg:pb-6
            mb-8 lg:mb-0
            w-full lg:w-1/2
            bg-slate-50
            lg:rounded
        ">
            <div className="mb-6">
                <BlockContent blocks={block.headline} serializers={{
                    types: {
                        block: (props: any) => (
                            <h2 className="mb-1 text-lg leading-6 sm:text-xl sm:leading-8 lg:text-2xl lg:leading-10 text-slate-900 font-semibold">{props?.children}</h2>
                        ),
                    },
                }} />
                <BlockContent blocks={block.content} serializers={{
                    types: {
                        block: (props: any) => (
                            <p className="mb-2 text-base leading-relaxed lg:text-lg text-slate-600">{props?.children}</p>
                        ),
                    },
                }} />
            </div>

            {block.listing && block.listing.map(list => <Listing list={list} key={list.listId} />)}
        </article>
    );
};

export default Article;
