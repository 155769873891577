import { Status } from '../types';
import { IBlocksState } from '../types/blocks';
import { LegalAction, TLegalAction } from '../types/legal';

export const initialLegalState: IBlocksState = {
    bySlug: {},
    status: Status.IDLE,
}

const legalReducer = (state = initialLegalState, action: TLegalAction): IBlocksState => {
    switch (action.type)
    {
        case LegalAction.REQUEST_LEGAL_SUCCESS:
            return action.payload;

        case LegalAction.REQUEST_LEGAL_PENDING:
            return {
                bySlug: state.bySlug,
                status: Status.PENDING,
            };

        case LegalAction.REQUEST_LEGAL_REJECTED:
            return {
                bySlug: state.bySlug,
                status: Status.REJECTED,
            };

        default:
            return state;
    }
};

export default legalReducer;
