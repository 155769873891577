import React from 'react';
import Header from '../../components/Header';

const NotFound = (): React.ReactElement => {
    return (
        <>
            <header>
                <Header />
            </header>
            <main className="container mx-auto">
                <article className="w-full px-4 pt-6 md:px-6 lg:px-10 lg:pt-10">
                    <h1 className="mb-1 text-lg leading-6 sm:text-xl sm:leading-8 lg:text-2xl lg:leading-10 text-slate-900 font-semibold">404 Not Found</h1>
                    <p className="mb-2 text-base leading-relaxed lg:text-lg text-slate-600">Die Seite wurde nicht gefunden.</p>
                </article>
            </main>
        </>
    );
}

export default NotFound;
