import { Status } from '../types';
import { TBlocks } from '../types/blocks';
import { IRequestLegalAction, IRequestLegalPendingAction, IRequestLegalRejectedAction, IRequestLegalSuccessAction, LegalAction } from '../types/legal';

export const requestLegal = (): IRequestLegalAction => ({
    type: LegalAction.REQUEST_LEGAL,
});

export const requestLegalPending = (): IRequestLegalPendingAction => ({
    type: LegalAction.REQUEST_LEGAL_PENDING,
});

export const requestLegalRejected = (): IRequestLegalRejectedAction => ({
    type: LegalAction.REQUEST_LEGAL_REJECTED,
});

export const requestLegalSuccess = (blocks: TBlocks): IRequestLegalSuccessAction => ({
    type: LegalAction.REQUEST_LEGAL_SUCCESS,
    payload: {
        bySlug: blocks,
        status: Status.LOADED,
    },
});
