import sanityClient from "@sanity/client";
import { IFetchProps } from "./types";
import { IBlock } from "../store/types/blocks";

export const sanityConfig = {
    projectId: 'qzvlwijk',
    dataset: 'production',
    apiVersion: '2021-12-01',
    useCdn: true,
};

const client = sanityClient(sanityConfig);

export default client;

export const fetch = async (props: IFetchProps): Promise<unknown> => {
    const options = props.options ? props.options : {};

    try {
        return await client.fetch<Array<IBlock>>(props.query, options);
    } catch(e) {
        console.error(e);
    }
};
