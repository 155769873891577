import { takeLatest, put, call, ForkEffect } from 'redux-saga/effects';
import { fetch } from "../../sanity";
import { queryHomepage } from "../../sanity/query/homepage";
import { transformBlocks } from '../../utils/transforms';
import { IBlock, TBlocks } from '../types/blocks';
import { HomepageAction } from '../types/homepage';
import { requestHomepagePending, requestHomepageRejected, requestHomepageSuccess } from '../actions/homepage';

function* requestHomepage() {
    yield put(requestHomepagePending());

    try {
        const response: Promise<Array<IBlock>> = yield call(fetch, queryHomepage);
        const blocks: TBlocks = yield transformBlocks<IBlock, TBlocks>(response);

        yield put(requestHomepageSuccess(blocks));
    }
    catch (error) {
        yield put(requestHomepageRejected());

        if (error instanceof Error) {
            console.error('saga requestHomepage() error:', error);
        }
    }
}

export function* watchRequestHomepage(): Generator<ForkEffect<HomepageAction>, void, number> {
    yield takeLatest(HomepageAction.REQUEST_HOMEPAGE, requestHomepage);
}
