import React from 'react';
import { useSelector } from 'react-redux';
import BlockContent from '@sanity/block-content-to-react';
import { getCaptchaString, getCaptchaValue } from '../../utils/captchaHelper';
import { TContactAlert, TContactForm, TContactMailerError } from './types';
import { selectBlock } from '../../store/selectors/homepage';

const Contact = (): React.ReactElement => {
    const content = useSelector(selectBlock('contact'));

    const initialData = {
        email: '',
        name: '',
        message: '',
        captcha: {
            result: '',
            first: getCaptchaValue(),
            second: getCaptchaValue(),
        },
    };
    const [data, setData] = React.useState<TContactForm>(initialData);
    const [alert, setAlert] = React.useState<null | TContactAlert>(null);
    const [captchaLabel, setCaptchaLabel] = React.useState<string>('');

    React.useEffect(() => setCaptchaLabel(getCaptchaString(data.captcha.first, data.captcha.second)), [data]);

    const handleInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { target: { value, name: field }, } = event;

        if (field === 'captcha') {
            setData({
                ...data,
                captcha: {
                    first: data.captcha.first,
                    second: data.captcha.second,
                    result: parseInt(value),
                },
            });
            return;
        }

        if (['name', 'email', 'message'].indexOf(field) > -1) {
            setData({ ...data, [field]: value });
            return;
        }
    };

    const handleSubmitError = (obj: TContactMailerError) => {
        setAlert(null);
        setData({ ...data, captcha: {
            first: getCaptchaValue(),
            second: getCaptchaValue(),
            result: '',
        }});

        let alertText = 'Ein unbekannter Fehler ist aufgetreten!';

        switch (obj.errors[0]) {
            case 'ERR_EMPTY':
                alertText = 'Bitte füllen Sie das ganze Formular aus!';
                break;
            case 'ERR_MAIL_NOT_VALID':
                alertText = 'Bitte tragen Sie eine valide Email-Adresse ein!';
                break;
            case 'ERR_SPAM_RESULT':
                alertText = 'Bitte lösen Sie den Spamschutz!';
                break;
        }

        setAlert({ type: 'error', text: alertText, });
    };

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const body = JSON.stringify({
            to: data.email,
            name: data.name,
            message: data.message,
            spam_result: data.captcha.result,
            spam_first: data.captcha.first,
            spam_second: data.captcha.second,
        });

        await fetch(`${process.env.REACT_APP_MAILER_API_BASE_URL}/v1/send`, {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body,
        })
            .then((resp) => {
                if (!resp.ok) throw Error(resp.statusText);
                return resp.json();
            })
            .then((obj) => {
                if (obj.success) {
                    setAlert({ type: 'success', text: 'Nachricht erfolgreich versendet!', });
                    setData(initialData);
                }
                if (obj.errors) {
                    handleSubmitError(obj);
                }
            })
            .catch(err => console.error(err));
    };

    if (!content) return (<p>Loading ...</p>);

    return (
        <section>
            <div className="
                container mx-auto
                mb-16
                lg:flex lg:flex-row lg:flex-nowrap lg:justify-start lg:items-top
                lg:space-x-8
            ">
                <div className="p-4 md:pt-8 md:p-6 lg:p-10 w-full lg:w-1/2">
                    <BlockContent blocks={content.headline} serializers={{
                        types: {
                            block: (props: any) => (
                                <h3 className="mb-1 text-lg leading-6 sm:text-xl sm:leading-8 lg:text-2xl lg:leading-10 text-slate-900 font-semibold">{props?.children}</h3>
                            ),
                        },
                    }} />
                    <BlockContent blocks={content.content} serializers={{
                        types: {
                            block: (props: any) => (
                                <p className="mb-2 text-base leading-relaxed lg:text-lg text-slate-600">{props?.children}</p>
                            ),
                        },
                    }} />
                    <p className="text-base leading-relaxed lg:text-lg text-slate-600">Zudem finden Sie mich auf <a rel="noreferrer nofollow noopener" href="https://www.xing.com/profile/Florian_Rehder2" target="_blank" className="text-slate-900 underline decoration-slate-300 decoration-2 hover:text-blue-600 hover:decoration-blue-500 transition-colors ease-in-out delay-150">Xing</a>.</p>
                </div>
                <div className="p-4 md:py-8 md:p-6 lg:p-10 w-full lg:w-1/2">
                    <form>
                        {alert !== null && alert.type === 'success' && (
                            <div className="mb-6 px-4 py-3 text-green-700 text-base lg:text-lg bg-green-100 rounded">{alert.text}</div>
                        )}
                        {alert !== null && alert.type === 'error' && (
                            <div className="mb-6 px-4 py-3 text-red-700 text-base lg:text-lg bg-red-100 rounded">{alert.text}</div>
                        )}
                        <div className="lg:w-full lg:pt-2 lg:pb-4 lg:flex lg:flex-row lg:flex-nowrap lg:justify-between lg:items-top lg:gap-x-12">
                            <p className="pt-2 pb-4 lg:p-0 lg:w-1/2">
                                <label htmlFor="name" className="block text-slate-600 text-base lg:text-lg">Name</label>
                                <input type="text" name="name" id="name" value={data.name} onChange={handleInput} className="block w-full rounded border border-slate-300" />
                            </p>
                            <p className="py-4 lg:py-0 lg:w-1/2">
                                <label htmlFor="email" className="block text-slate-600 text-base lg:text-lg">Email</label>
                                <input type="email" name="email" id="email" value={data.email} onChange={handleInput} className="block w-full rounded border border-slate-300" />
                            </p>
                        </div>
                        <p className="py-4">
                            <label htmlFor="message" className="block text-slate-600 text-base lg:text-lg">Nachricht</label>
                            <textarea name="message" id="message" onChange={handleInput} value={data.message} className="block w-full rounded border border-slate-300" />
                        </p>
                        <p className="py-4">
                            <label htmlFor="captcha" className="block text-slate-600 text-base lg:text-lg">{captchaLabel}</label>
                            <input type="number" step="1" min="0" name="captcha" id="captcha" value={data.captcha.result} onChange={handleInput} className="block w-full rounded border border-slate-300" />
                        </p>
                        <p className="py-4">
                            <button type="submit" onClick={handleSubmit} className="px-8 py-2 rounded bg-blue-500 hover:bg-blue-400 active:bg-blue-600 drop-shadow text-white transition ease-in-out hover:transition-all">Send</button>
                        </p>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Contact;
