import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BlockContent from '@sanity/block-content-to-react';
import { ILegalProps } from './types';
import { requestLegal } from '../../store/actions/legal';
import { selectBlock, selectStatus } from '../../store/selectors/legal';
import { Status } from '../../store/types';
import Loader from '../../components/Loader';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const Legal = ({ block }: ILegalProps): React.ReactElement => {
    const dispatch = useDispatch();
    const [requestPending, setRequestPending] = React.useState<boolean>(true);
    const pageStatus = useSelector(selectStatus);
    const content = useSelector(selectBlock(block));

    React.useEffect(() => {
        switch (pageStatus) {
            case Status.IDLE:
                dispatch(requestLegal());
                break;
            case Status.PENDING:
                setRequestPending(true);
                break;
            case Status.LOADED:
                setRequestPending(false);
                break;
        }
    }, [pageStatus, dispatch]);

    if (!content || requestPending) return (<Loader />);

    return (
        <>
            <Header />
            <main className="container mx-auto my-12">
                <article className="
                    max-w-screen-lg px-3 md:px-6 lg:px-10
                    prose prose-slate
                    prose-headings:font-semibold prose-headings:text-slate-900
                    prose-h1:text-2xl prose-h1:leading-10
                    prose-a:text-slate-900 prose-a:underline prose-a:decoration-slate-200 prose-a:decoration-2
                    hover:prose-a:text-blue-600 hover:prose-a:decoration-blue-500
                    prose-a:transition-colors prose-a:ease-in-out prose-a:delay-150
                    hover:prose-a:transition-colors hover:prose-a:ease-in-out hover:prose-a:delay-150
                ">
                    {!content ? (<Loader />) : (
                        <>
                            <h1>{(typeof content.headline === 'string') && content.headline}</h1>
                            <BlockContent blocks={content.content} serializers={{
                                types: {
                                    block: (props: any) => {
                                        const style = props.node.style;
                                        let content: Array<any> = [];

                                        props.children.map((item: any, index: number) => {
                                            if (typeof item === 'string' && item.includes('[email]')) {
                                                content.push(item.replace('[email]', '<'));
                                                content.push(React.createElement('span', {
                                                    key: item,
                                                    style: { unicodeBidi: 'bidi-override', direction: 'rtl' }
                                                }, 'ed.redhernairolf@lagel'));
                                                content.push('>');
                                                return index;
                                            }

                                            content.push(item);
                                            return index;
                                        });

                                        if (/^h\d/.test(style)) return React.createElement(style, {}, props.children);

                                        return React.createElement('p', {}, content);
                                    },
                                },
                                marks: {
                                    link: (props: any) => (
                                        <a rel="noreferrer nofollow noopener" href={props.mark.href} target="_blank">{props.children[0]}</a>
                                    ),
                                    strong: (props: any) => (<strong>{props.children}</strong>),
                                    em: (props: any) => (<em>{props.children}</em>),
                                },
                            }} />
                        </>
                    )}
                </article>
            </main>
            <Footer />
        </>
    );
}

export default Legal;
